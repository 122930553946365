@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

html {
  scroll-behavior: smooth;
}

button {
  font-family: 'Poppins', sans-serif !important;

}

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  padding: 0 3px;
  background-color: #199CFF;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: white;
  border-radius: 10px;
  border: solid 3.5px #199CFF;
  width: 5px;
}


.hero {
  background-image: url(./assets/images/hero.png);
  background-size: cover;
  background-repeat: no-repeat;
}

.layout {
  /* background-color: #0b487672; */
  color: white;
}

.shape {
  border-radius: 25% 25% 50% 50%;
  background-color: white;
}

.shape2 {
  background-color: #199CFF;
}

.back_img-2 {
  background-image: url(./assets/images/img4.png);
}

.slider {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.slide {
  flex: 0 0 100%;
  box-sizing: border-box;
  text-align: center;
  line-height: 200px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.slide1 {
  background-image: url(./assets/images/service1.png);
}

.slide2 {
  background-image: url(./assets/images/service2.png);
}

.slide3 {
  background-image: url(./assets/images/service3.png);
}

button {
  margin-top: 10px;
  cursor: pointer;
}

form {
  font-family: 'Manrope', sans-serif;
}

.h-auth {
  height: 100vh;
}